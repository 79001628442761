import Vue from 'vue'
import i18n from '@/libs/i18n'

const Errors = {
    InternalServerError: 1,
    InvalidData: 2,
    NotFound: 3,
    UserExist: 4,
    ISPUser: 5,
    NotISPUser: 6,
    IncorrectPassword: 7,
    UnableToRetrieveExtension: 8,
    IncorrectEmail: 9,
    DuplicateMacAddress: 10,
    DuplicateSerialNumber: 11,
    WrongType: 12,
    AttachmentIsNotImage: 13,
    MissingPermission: 14,
    MissingHubPermission: 15,
    MissingAddressPermission: 16,
    ForgottenPasswordRequestExpired: 17,
    InvalidCaptcha: 18,
    NoAccess: 19,
    NoAttachments: 20,
    IPAlreadyExists: 21,
    UserNotBelongCustomer: 22,
    UnableToEditOrDeleteRequest: 23,
    ONTInSameLocation: 24,
    ONTAlreadyAtBitstream: 25,
    ONTIsNotAtBitstream: 26,
    InvalidPoint: 27,
    InvalidStatus: 28,
    NotOwner: 29,
    FileTooLarge: 30,
    DuplicateISPServicePrice: 31,
    ISPServicePriceNotFound: 32,
    DuplicatePermanentService: 33,
    PriceConditionNotFound: 34,
    UnableToGenerateDataForSelectedDate: 35,
    UnableToAddRequestForDisabledItem: 36,
    RequestTypeWithStatusOpenedAlreadyExists: 37,
    ResolveIssueRequestsAreOpened: 38,
    ExcludedRequestsAreOpened: 39,
    AddressIsNotFree: 40,
    AddressIsFree: 41,
    MultipleRequestsWithStatusOpened: 42,
    NoDataToGenerateBilling: 43,

    // TASK & TASK WORKING TIME
    TaskIsRunning: 44,
    TaskIsFinished: 45,
    TaskIsNotRunning: 46,
    TaskIsNotStopped: 47,
    InvalidTeam: 48,
    UserNotInTeam: 49,
    UserAlreadyWorkingOnTask: 50,
    TaskWorkingTimeIsRunning: 51,
    UserDoesNotOwnTask: 52,
    UserDoesNotOwnTaskWorkingTime: 53,
    TeamIsAssigned: 54,
    InvalidTaskStatusToReassignTeam: 55,
    ImportTemplateNotExist: 56,
    ImportTemplateNameExists: 57,
    DefaultImportTemplateCantBeDeleted: 58,
    InvalidInputPoint: 59,
    IncidentStarted: 60,
    UnableToPause: 61,
    UnableToStop: 62,
    InvalidConversionType: 63,
    ParsingError: 64,
    IncidentIsNotStopped: 65,
    IncidentIsRunning: 66,
    UnableToSendSMS: 67,
    TaskStatusAlreadySet: 68
}

function parseError(error, fallback) {
    if (error && error.response && error.response.status === 403) {
        return i18n.t('error.missing_permission')
    } else if (!error.response || !error.response.data || !error.response.data.code || (error.response.data.code === Errors.InternalServerError)) {
        if (fallback && fallback !== '') return fallback
        return i18n.t('error.there_was_an_error')
    }

    switch (error.response.data.code) {
        case Errors.InvalidData:
            return i18n.t('error.invalid_data')
        case Errors.NotFound:
            return i18n.t('error.not_found')
        case Errors.UserExist:
            return i18n.t('error.user_exist')
        case Errors.ISPUser:
            return i18n.t('error.isp_user')
        case Errors.NotISPUser:
            return i18n.t('error.not_isp_user')
        case Errors.IncorrectPassword:
            return i18n.t('error.incorrect_password')
        case Errors.UnableToRetrieveExtension:
            return i18n.t('error.incorrect_format')
        case Errors.IncorrectEmail:
            return i18n.t('error.incorrect_email')
        case Errors.DuplicateMacAddress:
            return i18n.t('error.duplicate_mac_address')
        case Errors.DuplicateSerialNumber:
            return i18n.t('error.duplicate_serial_number')
        case Errors.WrongType:
            return i18n.t('error.wrong_type')
        case Errors.AttachmentIsNotImage:
            return i18n.t('error.attachment_is_not_image')
        case Errors.MissingPermission:
            return i18n.t('error.missing_permission')
        case Errors.MissingHubPermission:
            return i18n.t('error.missing_hub_permission')
        case Errors.MissingAddressPermission:
            return i18n.t('error.missing_addresses_permission')
        case Errors.ForgottenPasswordRequestExpired:
            return i18n.t('error.forgotten_password_request_was_expired')
        case Errors.InvalidCaptcha:
            return i18n.t('error.invalid_captcha')
        case Errors.NoAccess:
            return i18n.t('error.no_access')
        case Errors.NoAttachments:
            return i18n.t('error.no_attachments')
        case Errors.IPAlreadyExists:
            return i18n.t('error.ip_already_exists')
        case Errors.UserNotBelongCustomer:
            return i18n.t('error.user_not_belong_operator')
        case Errors.UnableToEditOrDeleteRequest:
            return i18n.t('error.unable_to_edit_or_delete_request')
        case Errors.ONTInSameLocation:
            return i18n.t('error.ont_in_same_location')
        case Errors.ONTAlreadyAtBitstream:
            return i18n.t('error.ont_already_at_bitstream')
        case Errors.ONTIsNotAtBitstream:
            return i18n.t('error.ont_not_at_bitstream')
        case Errors.InvalidPoint:
            return i18n.t('error.invalid_point')
        case Errors.InvalidStatus:
            return i18n.t('error.invalid_status')
        case Errors.NotOwner:
            return i18n.t('error.not_owner')
        case Errors.FileTooLarge:
            return i18n.t('error.file_too_large')
        case Errors.DuplicateISPServicePrice:
            return i18n.t('error.duplicate_isp_service_price')
        case Errors.ISPServicePriceNotFound:
            return i18n.t('error.isp_service_price_not_found')
        case Errors.DuplicatePermanentService:
            return i18n.t('error.duplicate_permanent_service')
        case Errors.PriceConditionNotFound:
            return i18n.t('error.price_condition_not_found')
        case Errors.UnableToGenerateDataForSelectedDate:
            return i18n.t('error.unable_to_generate_data_for_selected_date')
        case Errors.UnableToAddRequestForDisabledItem:
            return i18n.t('error.unable_to_add_request_for_disabled_item')
        case Errors.RequestTypeWithStatusOpenedAlreadyExists:
            return i18n.t('error.request_type_with_status_opened_already_exists')
        case Errors.ResolveIssueRequestsAreOpened:
            return i18n.t('error.resolve_issue_requests_are_opened')
        case Errors.ExcludedRequestsAreOpened:
            return i18n.t('error.excluded_requests_are_opened')
        case Errors.AddressIsNotFree:
            return i18n.t('error.address_is_not_free')
        case Errors.AddressIsFree:
            return i18n.t('error.address_is_free')
        case Errors.MultipleRequestsWithStatusOpened:
            return i18n.t('error.multiple_requests_with_status_opened')
        case Errors.NoDataToGenerateBilling:
            return i18n.t('error.no_data_to_generate_billing')
        case Errors.ImportTemplateNotExist:
            return i18n.t('error.template_no_exist')
        case Errors.ImportTemplateNameExists:
            return i18n.t('error.template_name_exists')
        case Errors.DefaultImportTemplateCantBeDeleted:
            return i18n.t('error.default_template_delete')
        case Errors.InvalidInputPoint:
            return i18n.t('error.invalid_input_point')
        case Errors.IncidentStarted:
            return i18n.t('error.incident_started')
        case Errors.UnableToPause:
            return i18n.t('error.unable_to_pause')
        case Errors.UnableToStop:
            return i18n.t('error.unable_to_stop')
        case Errors.InvalidConversionType:
            return i18n.t('error.invalid_conversion_type')
        case Errors.ParsingError:
            return i18n.t('error.parsing_error')
        case Errors.IncidentIsNotStopped:
            return i18n.t('error.incident_is_not_stopped')
        case Errors.IncidentIsRunning:
            return i18n.t('error.incident_is_running')
        case Errors.UnableToSendSMS:
            return i18n.t('error.unable_to_send_sms')
        case Errors.TaskStatusAlreadySet:
            return i18n.t('error.task_status_already_set')

        // TASK & TASK WORKING TIME
        case Errors.TaskIsRunning:
            return i18n.t('error.task_is_running')
        case Errors.TaskIsFinished:
            return i18n.t('error.task_is_finished')
        case Errors.TaskIsNotRunning:
            return i18n.t('error.task_is_not_running')
        case Errors.TaskIsNotStopped:
            return i18n.t('error.task_is_not_stopped')
        case Errors.InvalidTeam:
            return i18n.t('error.invalid_team')
        case Errors.UserNotInTeam:
            return i18n.t('error.user_not_in_team')
        case Errors.UserAlreadyWorkingOnTask:
            return i18n.t('error.user_already_working_on_task')
        case Errors.TaskWorkingTimeIsRunning:
            return i18n.t('error.task_working_time_is_running')
        case Errors.UserDoesNotOwnTask:
            return i18n.t('error.user_does_not_own_task')
        case Errors.UserDoesNotOwnTaskWorkingTime:
            return i18n.t('error.user_does_not_own_task_working_time')
        case Errors.TeamIsAssigned:
            return i18n.t('error.team_is_assigned')
        case Errors.InvalidTaskStatusToReassignTeam:
            return i18n.t('error.invalid_task_status_to_reassign_team')
        default:
            return i18n.t('error.there_was_an_error')
    }
}

Vue.prototype.$Errors = Errors
Vue.prototype.$parseError = parseError

export default Errors
