import Vue from 'vue'
import { ModalPlugin, VBTooltipPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import PortalVue from 'portal-vue'

import router from './router'

// Global Components
import './global-components'

// 3rd party plugins

import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/axios'
import '@/libs/font-awesome'
import '@/libs/dayjs'
import '@/libs/helpers'
import '@/libs/request'
import '@/libs/ticket'
import '@/libs/locales'

import '@/libs/error/index'
import '@/libs/notification'
import '@/libs/enums/request/bitstreamRequest'
import '@/libs/connection'
import '@/libs/enums/connection'
import '@/libs/incident'
import '@/libs/service'
import '@/libs/enums/addresses'
import coordinateConverter from '@/libs/enums/coordinateConverter'
import '@/libs/enums/controlPanel'
import ONTEnums from '@/libs/enums/ont'
import ONTModels from '@/libs/ont/ONTModels'
import request from '@/libs/enums/request'
import hubRequest from '@/libs/enums/request/hubRequest'
import connectionRequest from '@/libs/enums/request/connectionRequest'
import ticketEnums from '@/libs/enums/ticket'
import incident from '@/libs/enums/incident'
import taskEnums from '@/libs/enums/task'

// enums
Vue.prototype.$ONT = ONTEnums
Vue.prototype.$ONTModels = ONTModels
Vue.prototype.$request = request
Vue.prototype.$hubRequest = hubRequest
Vue.prototype.$connectionRequest = connectionRequest
Vue.prototype.$ticket = ticketEnums
Vue.prototype.$nullId = '000000000000000000000000'
Vue.prototype.$nullDateFlatPickr = '0001-01-01T00:00:00.00Z'
Vue.prototype.$nullDate = '0001-01-01T00:00:00Z'
Vue.prototype.$task = taskEnums
Vue.prototype.$incident = incident
Vue.prototype.$coordinateConverter = coordinateConverter

// BSV Plugin Registration
Vue.use(VBTooltipPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(PortalVue)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import '@/main.css'

Vue.config.productionTip = false

import i18n from '@/libs/i18n'
Vue.prototype.$supported_phone_formats = '092 123 4567<br>01 234 5678<br>385 92 123 4567'

async function init() {
    const store = (await import('@/store')).default
    const App = (await import('@/App.vue')).default

    await store.dispatch('user/loadUserPermissions')

    new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app')
}

init()