import i18n from '@/libs/i18n'
import Vue from 'vue'
import VueRouter from 'vue-router'
import permissions from '@/permissions'

Vue.use(VueRouter)

/* webpackPrefetch: true */
/*
 * webpack magic commet, which will prefetch next page.
 * if we know that user is definitely going to that one page (for example If users is going to products page while in categories, we add comment to products)
 * this will add < link rel="prefetch" href="/products" />
 */

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to) {
        if (!('noScroll' in to.meta)) {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackPrefetch: true */ '@/views/Home.vue'),
            meta: {
                pageTitle: () => i18n.t('general.control_panel_title'),
                requiresAuth: true
            }
        },

        {
            path:'/hubs',
            name:'hubs',
            component:() => import('@/views/hub/Hubs.vue'),
            meta:{
                pageTitle: () => i18n.t('hubs.title'),
                requiresAuth: true,
                requiredPermission: permissions.ReadHub
            }
        },
        {
            path:'/hub/:id',
            name:'hub',
            component:() => import('@/views/hub/Hub'),
            meta:{
                pageTitle: () => i18n.t('hubs.hub'),
                requiresAuth: true,
                requiredPermission: permissions.ReadHub
            }
        },
        {
            path:'/incidents/all',
            name:'incidents/all',
            component:() => import('@/views/incidents/AllIncidents.vue'),
            meta:{
                pageTitle: () => i18n.t('incident.incidents'),
                active:true,
                requiresAuth: true,
                requiredPermission: permissions.ReadAllIncidents
            }
        },
        {
            path:'/incidents/own',
            name: 'incidents/own',
            component: () => import('@/views/incidents/OwnIncidents.vue'),
            meta:{
                pageTitle: () => i18n.t('incident.own_incidents'),
                active:true,
                requiresAuth: true,
                requiredPermission: permissions.ReadOwnIncidents
            }
        },
        {
            path: '/incident/:id',
            name: 'incident',
            component: () => import('@/views/incidents/Incident'),
            meta:{
                pageTitle: () => i18n.t('incident.incident'),

                active:true,
                requiresAuth: true,
                requiredPermission: [[permissions.ReadOwnIncidents, permissions.ReadAllIncidents]]
            }
        },
        {
            path:'/connections',
            name:'connections',
            component:() => import('@/views/connection/Connections.vue'),
            meta:{
                pageTitle: () => i18n.t('connections.passive'),
                requiresAuth: true,
                requiredPermission: permissions.ReadConnection
            }
        },
        {
            path:'/connection/:id',
            name:'connection',
            component:() => import('@/views/connection/Connection'),
            meta:{
                pageTitle: () => i18n.t('connections.passive'),
                requiresAuth: true,
                requiredPermission: permissions.ReadConnection
            }
        },
        {
            path:'/bitstreams',
            name:'bitstreams',
            component:() => import('@/views/bitstream/Bitstreams'),
            meta:{
                pageTitle: () => i18n.t('bitstream.active_bitstream'),
                requiresAuth: true,
                requiredPermission:  permissions.ReadBitstream
            }
        },
        {
            path:'/bitstream/:id',
            name:'bitstream',
            component:() => import('@/views/bitstream/Bitstream'),
            meta:{
                pageTitle: () => i18n.t('bitstream.active_bitstream'),
                requiresAuth: true,
                requiredPermission: permissions.ReadBitstream
            }
        },
        {
            path:'/requests',
            name:'requests',
            component:() => import('@/views/request/Requests'),
            meta:{
                pageTitle: () => i18n.t('request.requests'),
                requiresAuth: true,
                requiredPermission: [[permissions.ReadHubRequest], [permissions.ReadBitstreamRequest], [permissions.ReadConnectionRequest], [permissions.ReadAndWriteAllRequests]]
            }
        },
        {
            path:'/request/:id',
            name:'request',
            component:() => import('@/views/request/Request'),
            meta:{
                pageTitle: () => i18n.t('request.request'),
                requiresAuth: true,
                requiredPermission: [[permissions.ReadHubRequest], [permissions.ReadBitstreamRequest], [permissions.ReadConnectionRequest], [permissions.ReadAndWriteAllRequests]]
            }
        },
        {
            path:'/isp-operators',
            name:'isps',
            component:() => import('@/views/isp/ISPs.vue'),
            meta:{
                pageTitle: () => i18n.t('isp.operators'),
                requiresAuth: true,
                requiredPermission: permissions.ReadISP
            }
        },
        {
            path:'/isp-operator/:id',
            name:'isp',
            component:() => import('@/views/isp/ISP.vue'),
            meta:{
                pageTitle: () => i18n.t('isp.operator'),
                requiresAuth: true,
                requiredPermission: permissions.ReadISP
            }
        },
        {
            path:'/isp/users',
            name:'isp_users',
            component:() => import('@/views/isp/ISPUsers.vue'),
            meta:{
                pageTitle: () => `${i18n.t('isp.operators')} - ${  i18n.t('user.users').toLowerCase()}`,
                requiresAuth: true,
                requiredPermission: permissions.ReadISPUsers
            }
        },
        {
            path: '/settings',
            name: 'settings',
            meta: {
                requiresAuth: true
            }
        },
        {
            path:'/settings/users',
            name:'users',
            component:() => import('@/views/settings/user/Users.vue'),
            meta:{
                pageTitle: () => i18n.t('user.employees'),
                requiresAuth: true,
                requiredPermission: permissions.ReadUsersAndRoles
            }
        },
        {
            path:'/settings/incident-contacts',
            name:'incident_contacts',
            component:() => import('@/views/settings/incidentContact/IncidentContacts'),
            meta: {
                pageTitle: () => i18n.t('incident_contact.incident_contacts'),
                requiresAuth: true,
                requiredPermission: permissions.ReadIncidentContact
            }
        },
        {
            path: '/settings/roles',
            name: 'settings-roles',
            component: () => import('@/views/settings/roles/Roles'),
            meta: {
                pageTitle: () => i18n.t('settings.roles'),
                requiresAuth: true,
                requiredPermission: permissions.ReadUsersAndRoles
            }
        },
        {
            path:'/settings/team/tags',
            name:'team_tags',
            component:() => import('@/views/settings/teamTag/TeamTags'),
            meta: {
                pageTitle: () => i18n.t('team_tag.team_tags'),
                requiresAuth: true,
                requiredPermission: permissions.ReadTeamTags
            }
        },
        {
            path:'/settings/incident/tags',
            name:'incident_tags',
            component:() => import('@/views/settings/incidentTag/IncidentTags'),
            meta: {
                pageTitle: () => i18n.t('incident_tag.incident_tags'),
                requiresAuth: true,
                requiredPermission: permissions.ReadIncidentTag
            }
        },
        {
            path:'/settings/ticket/names',
            name:'ticket_names',
            component:() => import('@/views/settings/ticketName/TicketNames'),
            meta: {
                pageTitle: () => i18n.t('ticket_name.ticket_names'),
                requiresAuth: true,
                requiredPermission: permissions.ReadTicketName
            }
        },
        {
            path:'/settings/task/types',
            name:'task_types',
            component:() => import('@/views/settings/taskType/TaskTypes'),
            meta: {
                pageTitle: () => i18n.t('task_type.task_types'),
                requiresAuth: true,
                requiredPermission: permissions.ReadTaskType
            }
        },
        {
            path:'/settings/incident/types',
            name:'incident_types',
            component:() => import('@/views/settings/incidentType/IncidentTypes'),
            meta: {
                pageTitle: () => i18n.t('incident_type.incident_types'),
                requiresAuth: true,
                requiredPermission: permissions.ReadIncidentType
            }
        },
        {
            path: '/settings/api-keys',
            name: 'api_keys',
            component:() => import('@/views/settings/APIKey/APIKeys.vue'),
            meta:{
                pageTitle: () => i18n.t('api_key.api_keys'),
                requiresAuth: true,
                requiredPermission: permissions.ReadAPIKey
            }
        },
        {
            path:'/settings/request_options',
            name:'request_options',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings/request_options/request_type',
            name: 'request_type',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.request_type'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/activation_type',
            name: 'activation_type',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.activation_type'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/query_type',
            name: 'query_type',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.query_type'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/issue_type',
            name: 'issue_type',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.issue_type'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/exclusion_type',
            name: 'exclusion_type',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.exclusion_type'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/connection_method',
            name: 'connection_method',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.connection_method'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/planned_topology',
            name: 'planned_topology',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.planned_topology'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        //=============================================================================================
        {
            path: '/settings/request_options/planned_capacity_ru_number',
            name: 'planned_capacity_ru_number',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.planned_capacity_ru_number'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/number_of_splitters_for_instalation',
            name: 'number_of_splitters_for_instalation',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.number_of_splitters_for_instalation'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/number_of_ru_splitters',
            name: 'number_of_ru_splitters',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.number_of_ru_splitters'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/number_of_possible_new_connection',
            name: 'number_of_possible_new_connection',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.number_of_possible_new_connection'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/number_of_ru_for_network_and_splitters',
            name: 'number_of_ru_for_network_and_splitters',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.number_of_ru_for_network_and_splitters'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path: '/settings/request_options/number_of_threads',
            name: 'number_of_threads',
            component:() => import('@/views/settings/requestOptions/RequestOptions.vue'),
            meta:{
                pageTitle: () => i18n.t('request_options.number_of_threads'),
                requiresAuth: true,
                requiredPermission: permissions.ReadRequestOptions
            }
        },
        {
            path:'/work-plan/tasks',
            name:'tasks',
            component:() => import('@/views/workPlan/task/Tasks.vue'),
            meta:{
                pageTitle: () => i18n.t('task.tasks'),
                requiresAuth: true,
                requiredPermission: permissions.ReadAllCalendarTask
            }
        },
        {
            path:'/work-plan/task/:id',
            name:'task',
            component:() => import('@/views/workPlan/task/Task.vue'),
            meta:{
                pageTitle: () => i18n.t('task.task'),
                requiresAuth: true,
                requiredPermission: permissions.ReadAllCalendarTask
            }
        },
        {
            path:'/work-plan/teams',
            name:'work_plan_teams',
            component:() => import('@/views/workPlan/team/Teams'),
            meta:{
                pageTitle: () => i18n.t('team.teams'),
                requiresAuth: true,
                requiredPermission: [[permissions.ReadTeams, permissions.ReadUsersAndRoles, permissions.ReadTeamTags]]
            }
        },
        {
            path:'/work-plan/employees',
            name:'work_plan_employees',
            component:() => import('@/views/workPlan/employee/Employees'),
            meta:{
                pageTitle: () => i18n.t('work_plan.employees'),
                requiresAuth: true,
                requiredPermission: permissions.ReadEmployee
            }
        },
        /*{
            path:'/chart/snmp',
            name:'charts_snmp',
            component:() => import('@/views/chart/ChartSNMP.vue'),
            meta:{
                pageTitle: () => `${i18n.t('chart.charts')} - ${i18n.t('chart.snmp')}`,
                requiresAuth: true,
                requiredPermission: permissions.ReadSNMPChart
            }
        },*/
        {
            path: '/suppliers',
            name: 'suppliers',
            component:() => import('@/views/supplier/Suppliers.vue'),
            meta:{
                pageTitle: () => i18n.t('supplier.suppliers'),
                requiresAuth: true,
                requiredPermission: permissions.ReadSupplier
            }
        },
        {
            path: '/supplier/:id',
            name: 'supplier',
            component:() => import('@/views/supplier/Supplier.vue'),
            meta:{
                pageTitle: () => i18n.t('supplier.supplier'),
                requiresAuth: true,
                requiredPermission: permissions.ReadSupplier
            }
        },
        {
            path: '/onts',
            name: 'onts',
            component:() => import('@/views/ont/ONTs.vue'),
            meta:{
                pageTitle: () => i18n.t('ont.onts'),
                requiresAuth: true,
                requiredPermission: permissions.ReadONT
            }
        },
        {
            path: '/ont/:id',
            name: 'ont',
            component:() => import('@/views/ont/ONT.vue'),
            meta:{
                pageTitle: () => i18n.t('ont.ont'),
                requiresAuth: true,
                requiredPermission: permissions.ReadONT
            }
        },
        {
            path: '/warehouses',
            name: 'warehouses',
            component:() => import('@/views/warehouse/Warehouses.vue'),
            meta:{
                pageTitle: () => i18n.t('warehouse.warehouses'),
                requiresAuth: true,
                requiredPermission: permissions.ReadWarehouse
            }
        },
        {
            path: '/warehouse/:id',
            name: 'warehouse',
            component:() => import('@/views/warehouse/Warehouse.vue'),
            meta:{
                pageTitle: () => i18n.t('warehouse.warehouse'),
                requiresAuth: true,
                requiredPermission: permissions.ReadWarehouse
            }
        },
        {
            path: '/tickets',
            name: 'tickets',
            component:() => import('@/views/ticket/Tickets.vue'),
            meta:{
                pageTitle: () => i18n.t('ticket.all_tickets'),
                requiresAuth: true,
                requiredPermission: [[permissions.ReadHubTicket], [permissions.ReadBitstreamTicket], [permissions.ReadConnectionTicket], [permissions.ReadRequestTicket], [permissions.ReadAndWriteAllTickets]]
            }
        },
        {
            path: '/own-tickets',
            name: 'own-tickets',
            component:() => import('@/views/ticket/OwnTickets.vue'),
            meta:{
                pageTitle: () => i18n.t('ticket.own_tickets'),
                requiresAuth: true,
                requiredPermission: permissions.ReadOwnTickets
            }
        },
        {
            path: '/ticket/:id',
            name: 'ticket',
            component:() => import('@/views/ticket/Ticket.vue'),
            meta:{
                pageTitle: () => i18n.t('ticket.ticket'),
                requiresAuth: true,
                requiredPermission: [[permissions.ReadHubTicket], [permissions.ReadBitstreamTicket], [permissions.ReadConnectionTicket], [permissions.ReadRequestTicket], [permissions.ReadAndWriteAllTickets]]
            }
        },
        {
            path: '/services/bitstream-prices',
            name: 'bitstream_prices',
            component:() => import('@/views/service/ISPPrice/BitstreamPrices.vue'),
            meta:{
                pageTitle: () => i18n.t('service.bitstream_prices'),
                requiresAuth: true,
                requiredPermission: permissions.ReadService
            }
        },
        {
            path: '/services/connection-prices',
            name: 'connection_prices',
            component:() => import('@/views/service/ISPPrice/ConnectionPrices.vue'),
            meta:{
                pageTitle: () => i18n.t('service.connection_prices'),
                requiresAuth: true,
                requiredPermission: permissions.ReadService
            }
        },
        {
            path: '/services/one-time',
            name: 'one_time_services',
            component:() => import('@/views/service/OneTimeService/OneTimeServices.vue'),
            meta:{
                pageTitle: () => i18n.t('service.one_time_services'),
                requiresAuth: true,
                requiredPermission: permissions.ReadService
            }
        },
        {
            path: '/services/permanent',
            name: 'permanent_services',
            component:() => import('@/views/service/permanentService/PermanentServices.vue'),
            meta:{
                pageTitle: () => i18n.t('service.permanent_services'),
                requiresAuth: true,
                requiredPermission: permissions.ReadService
            }
        },
        {
            path: '/billing',
            name: 'billing',
            component:() => import('@/views/billing/Billing.vue'),
            meta:{
                pageTitle: () => i18n.t('billing.billing'),
                requiresAuth: true,
                requiredPermission: permissions.ReadService
            }
        },
        {
            path:'/profile',
            name:'profile',
            component:() => import('@/views/profile/Profile'),
            meta:{
                pageTitle: i18n.t('general.profile'),
                requiresAuth: true
            }
        },
        {
            path:'/olt/configuration',
            name: 'olt_configuration',
            component:() => import('@/views/OLT/OLTConfigurations.vue'),
            meta:{
                pageTitle: () => `${i18n.t('provisioning.provisioning')} - ${i18n.t('OLT.OLT').toLowerCase()} - ${i18n.t('OLT.OLTconfig')}`,
                active:true,
                requiresAuth: true,
                requiredPermission: permissions.ReadOLTConfiguration
            }
        },
        {
            path: '/forgotten-password',
            name: 'forgotten-password',
            component: () => import('@/views/account/ForgottenPassword.vue'),
            meta: {
                layout: 'full',
                requiresAuth: false
            }
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: () => import('@/views/account/ResetPassword.vue'),
            meta: {
                layout: 'full',
                requiresAuth: false
            }
        },
        {
            path: '/import/addresses-templates',
            name: 'import-templates',
            component:() => import('@/views/import/ImportTemplate/ImportTemplates.vue'),
            meta:{
                pageTitle: () => i18n.t('import_addresses.import_templates'),
                requiresAuth: true,
                requiredPermission: permissions.WriteImports
            }
        },
        {
            path: '/import/addresses',
            name: 'import-addresses',
            component:() => import('@/views/import/ImportAddressesFromCSV.vue'),
            meta:{
                pageTitle: () => i18n.t('import_addresses.import_addresses'),
                requiresAuth: true,
                requiredPermission: permissions.WriteImports
            }
        },
        {
            path: '/import/addresses/own',
            name: 'import-addresses-own',
            component:() => import('@/views/import/addresses/OwnImportedAddresses.vue'),
            meta:{
                pageTitle: () =>  i18n.t('import_addresses.my_imports'),
                requiresAuth: true,
                requiredPermission: permissions.ReadOwnImports
            }
        },
        {
            path: '/import/addresses/all',
            name: 'import-addresses-all',
            component:() => import('@/views/import/addresses/AllImportedAddresses.vue'),
            meta:{
                pageTitle: () =>  i18n.t('import_addresses.all_imports'),
                requiresAuth: true,
                active:true,
                requiredPermission: permissions.ReadAllImports
            }
        },
        {
            path: '/import/addresses/details/:id',
            name: 'import-addresses-overview',
            component:() => import('@/views/import/ImportAddressesOverview.vue'),
            meta:{
                pageTitle: () =>  i18n.t('import_addresses.import_addresses'),
                requiresAuth: true,
                active:true,
                requiredPermission:  [[permissions.ReadOwnImports, permissions.ReadAllImports]]
            }
        },
        {
            path: '/documents/hub',
            name: 'hub-documents',
            component:() => import('@/views/documents/HubDocuments.vue'),
            meta:{
                pageTitle: () =>  i18n.t('document.hub_documents'),
                requiresAuth: true,
                active: true,
                requiredPermission: [[permissions.ReadHubRequest], [permissions.ReadAndWriteAllRequests]]
            }
        },
        {
            path: '/documents/addresses',
            name: 'addresses-documents',
            component:() => import('@/views/documents/AddressesDocuments.vue'),
            meta:{
                pageTitle: () =>  i18n.t('document.addresses_documents'),
                requiresAuth: true,
                active: true,
                requiredPermission: [[permissions.ReadBitstreamRequest], [permissions.ReadConnectionRequest], [permissions.ReadAndWriteAllRequests]]
            }
        },
        {
            path: '/about',
            name: 'about',
            component:() => import('@/views/About.vue'),
            meta:{
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackPrefetch: true */ '@/views/account/Login.vue'),
            meta: {
                layout: 'full',
                requiresAuth: false
            }
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                requiresAuth: false
            }
        },
        {
            path: '*',
            redirect: 'error-404'
        }
    ]
})

/*
 * LOGIKA ZA PREVERJANJE AVTORIZACIJE UPORANIKA
 * LOGIKA ZA PRIDOBIVANJE PODATKOV IZ LOCAL STORAGE, KI SE SHRANIJO V VUEX
*/
import store from '@/store'
import authService from '@/auth/authService'
router.beforeEach((to, from, next) => {

    const loggedIn = localStorage.getItem('loggedIn')
    if (loggedIn === 'true') {
        store.dispatch('user/updateUserPermissions')

        store.state.user.loggedIn = true
        const userData = localStorage.getItem('userData')
        if (userData) {
            store.state.user.userData = JSON.parse(userData)
        }
    }

    if (!authService.isAuthenticated() && to.meta.requiresAuth !== false) {
        store.dispatch('user/setLogout')
        return next('/login')
    }

    if (to.meta.requiredPermission && !Vue.prototype.$hasPermissionGroup(to.meta.requiredPermission)) {
        return next('/')
    }

    next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
