import axios from '@/libs/axios'
import $permissions from '@/permissions'
import Vue from 'vue'
import i18n from '@/libs/i18n'

export default {
    namespaced: true,
    state: {
        loggedIn: localStorage.getItem('loggedIn') === 'true',
        userData: localStorage.getItem('userData') || null,
        permissions: JSON.parse(localStorage.getItem('permissions')) || null,
        isISP: false,
        runningIncidentWorkingTimes: null,
        runningTaskWorkingTimes: null,
        unreadNotifications: []
    },
    getters: {
        userId(state) {
            return state.userData.id
        },
        getUserData(state) {
            return state.userData
        },
        getIsUserISP(state) {
            return state.isISP
        },
        getRunningIncidentWorkingTimes(state) {
            return state.runningIncidentWorkingTimes
        },
        getRunningTaskWorkingTimes(state) {
            return state.runningTaskWorkingTimes
        },
        getUnreadNotifications(state) {
            return state.unreadNotifications
        }
    },
    mutations: {
        SET_LOG_IN(state, payload) {
            state.loggedIn = true
            state.userData = JSON.parse(payload)
        },
        SET_LOG_OUT(state) {
            state.loggedIn = false
        },
        REMOVE_USER_DATA(state) {
            state.userData = null
        },
        SET_USER_PERMISSIONS(state, permissions) {
            state.permissions = permissions
        },
        SET_RUNNING_INCIDENT_WORKING_TIME(state, payload) {
            state.runningIncidentWorkingTimes = payload
        },
        SET_RUNNING_TASK_WORKING_TIME(state, payload) {
            state.runningTaskWorkingTimes = payload
        },
        SET_UNREAD_NOTIFICATIONS(state, payload) {
            state.unreadNotifications = payload
        },
        SET_IS_USER_ISP(state) {
            const jwt = localStorage.getItem('accessToken')
            if (!jwt) return false
            const decoded = JSON.parse(atob(jwt.split('.')[1]))
            state.isISP = decoded.is_isp
        },
        SET_USER_DATA(state, payload) {
            state.userData = payload
        }
    },
    actions: {
        setLogin({ commit }, userData) {
            commit('SET_LOG_IN', JSON.stringify(userData))
            commit('SET_USER_PERMISSIONS', userData.permissions)
            localStorage.setItem('loggedIn', 'true')
            localStorage.setItem('userData', JSON.stringify(userData))
        },
        setUserData({ commit }, userData) {
            commit('SET_USER_DATA', userData)
            commit('app/SET_LOCALE', userData.locale, {root:true})
            localStorage.setItem('userData', JSON.stringify(userData))
        },
        setLogout({ commit }) {
            commit('SET_LOG_OUT')
            commit('REMOVE_USER_DATA')
            localStorage.setItem('loggedIn', 'false')
            localStorage.removeItem('userData')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('permissions')
        },
        // eslint-disable-next-line no-unused-vars
        setTokenString({ commit }, tokenString) {
            localStorage.setItem('accessToken', tokenString)
            commit('SET_IS_USER_ISP')
        },
        async getRunningIncidentWorkingTimes({commit, state}) {
            if (!state.loggedIn) return
            if (Vue.prototype.$hasPermission($permissions.ReadOwnIncidents)) {
                axios.get('/api/user/v1/incident_working_times/running').then(res => {
                    commit('SET_RUNNING_INCIDENT_WORKING_TIME', res.data)
                })
            }
        },
        async getRunningTaskWorkingTimes({commit, state}) {
            if (!state.loggedIn) return
            if (Vue.prototype.$hasPermission($permissions.ReadTaskWorkingTime)) {
                axios.get('/api/user/v1/task_working_times/running').then(res => {
                    commit('SET_RUNNING_TASK_WORKING_TIME', res.data)
                })
            }
        },
        async loadUserPermissions({commit, state}) {
            if (!state.loggedIn) return

            try {
                const response = await axios.get('/api/user/v1/permissions')
                commit('SET_USER_PERMISSIONS', response.data)
                localStorage.setItem('permissions', JSON.stringify(response.data))
            } catch (error) {
                Vue.prototype.$printError(Vue.prototype.$parseError(error, i18n.t('general.error_load_data')))
            }
        },
        updateUserPermissions({ commit, state }) {
            if (!state.loggedIn) return
            axios.get('/api/user/v1/permissions').then(function(response) {
                commit('SET_USER_PERMISSIONS', response.data)
                localStorage.setItem('permissions', JSON.stringify(response.data))
            })
        },
        getUnreadNotifications({ commit, state }) {
            if (!state.loggedIn) return
            axios.get('/api/user/v1/notifications/unread').then(function(response) {
                commit('SET_UNREAD_NOTIFICATIONS', response.data)
            })
        },
        async setIsUserISP({ state, commit }) {
            if (!state.loggedIn) return
            commit('SET_IS_USER_ISP')
        },
        async loadUserData({state, dispatch}) {
            try {
                if (!state.loggedIn) return

                const response = await Vue.prototype.$http.get('/api/user/v1/users/my_data/')
                dispatch('setUserData', response.data)
            } catch (error) {
                Vue.prototype.$printError(Vue.prototype.$parseError(error, i18n.t('general.error_load_data')))
            }
        }
    }
}
